<div class="containerGeneral">
  <div>
    <img id="logoGo" src="assets/media/img/logoDueDU.png" alt="logo" />
  </div>

  <div class="infoContainer">
    <section *ngIf="form == true">
      <div class="title">
        <h2 class="marbot">Datos del paciente</h2>
      </div>

      <div class="formgroup">
        <label for="idnombreal">Nombre</label>
        <input
          id="nombre"
          class="form-control"
          type="text"
          placeholder="Escribir.."
          [(ngModel)]="nombre"
        />
      </div>

      <div class="formgroup">
        <label for="idnombreal">Apellidos</label>
        <input
          id="apellido"
          class="form-control"
          type="text"
          placeholder="Escribir.."
          [(ngModel)]="apellidos"
        />
      </div>

      <div class="formgroup">
        <label for="idnombreal">Fecha de nacimiento</label>
        <input
          id="fechanac"
          class="form-control"
          type="date"
          placeholder="Escribir.."
          [(ngModel)]="fechanac"
        />
      </div>

      <div class="formgroup">
        <label for="iddirecc">Motivos de Consulta</label>
        <textarea
          maxlength="300"
          id="iddirecc"
          class="form-control"
          placeholder="Escribe brevemente el motivo de su consulta..."
          [(ngModel)]="motivo"
        ></textarea>
      </div>
      <div class="response" *ngIf="error == true">
        <p>{{ response }}</p>
      </div>
      <div class="trans">
        <button (click)="createPaciente()">Ir a la sala de espera</button>
      </div>
      <div class="trans">
        <a
          href="https://demo.gofacer.com/webapp/conference/salapruebas"
          target="_blank"
          >Realizar prueba de video</a
        >
      </div>
    </section>

    <div class="registrado" *ngIf="registrado == true">
      <p>
        Paciente <b>registrado con exito</b>, será transferido a la sala de
        espera y en breve será atendida/o por su enfermera/o.
      </p>
      <img src="/assets/media/img/ok.svg" alt="ok logo" />
    </div>
  </div>
</div>
