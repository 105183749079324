
export const environment = {
  production: true,
  backend: {
    baseURL: "https://admin.gofacer.com/api"
  },
    firebase: {
      apiKey: "AIzaSyDGioSGjB8tNd_uaA_eIfkYmOSN6VgZ3Io",
      authDomain: "mg-enfermeria.firebaseapp.com",
      projectId: "mg-enfermeria",
      storageBucket: "mg-enfermeria.appspot.com",
      messagingSenderId: "754119949841",
      appId: "1:754119949841:web:8bcfffcbb36d765ba1fbb0",
      measurementId: "G-1J7TB7FEDK"
    }, 
};
