<div class="containerGeneral">
    <div class="logoSala">
        <img  id="logoGo" src="assets/media/img/logoDueDU.png" alt="logo">
        <div>
            <img (click)="logout()" title="logout"src="assets/media/img/logout.svg" alt="log out">
            <a href="https://demo.gofacer.com/webapp/#/?conference=enfermera&name=Enfermera&pin=1234&role=host&callType=video" target="_blank">Ir a mi sala</a>
        </div>
       
    </div>
    <div class="datos">
        <div class="title">
            <img src="assets/media/img/salaespera.svg" alt="menu">
            <h1 translate>Sala de espera</h1>
            <img (click)="reload()" title="recargar sala de espera" src="assets/media/img/load.svg" alt="reload">
        </div>
        <div class="containerP">
            <div class="pacientes">
                <div *ngFor="let item of usuarios" >
                <app-boxsala [item]="item" (itemDetail)="getInfo($event)" ></app-boxsala>
                </div>
            </div> 
            <img id="linea" src="assets/media/img/lineaSeparadora.svg" >
        </div>
    </div>
    
</div>
    
 <div *ngIf="info==true" class="infoContainer">
        <div class="nombre">
            <h2>{{userInfo.nombre}} {{userInfo.apellidos}}</h2>
        </div>
        <div class="nac">
            <p><b>Fecha de Nacimiento: </b></p>
            <p>{{userInfo.fechanac | date:'dd-MM-yy'}}</p>
        </div>
        <div class="nac">
            <p><b>Hora conexión: </b></p>
            <p>{{userInfo.horaConexion | date:'hh:mm'}}</p>
        </div>
        <div class="motivo">
            <p><b>Motivo:</b></p>
            <p>{{userInfo.motivo}}</p>
        </div>
        
        <div class="trans">
            <button (click)="transfer()">Transferir a mi sala</button>
        </div>
    </div>