import { Component, OnInit } from '@angular/core';
import { AccesoService } from '../services/acceso.service';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
})
export class FormComponent implements OnInit {
  nombre: string = null;
  apellidos: string = null;
  fechanac: any = null;
  id: string = null;
  motivo: string = null;
  response: string = null;
  error:boolean =false;
  registrado:boolean=false;
  form:boolean=true;

  pacientes: Array<any>;
  idSala = 'b2RYkEaTJR8sXgGewzwl';
  constructor(private _pacientes: AccesoService) {}

  ngOnInit(): void {
    this.getPacientes();
  }

  getPacientes() {
    var idSala = this.idSala;
    this.pacientes = new Array();
    this._pacientes.getPacientes().subscribe((pacientes)=> {
      this.pacientes.splice(0,this.pacientes.length);
      pacientes.forEach((paciente) => {
        this.pacientes.push(paciente.payload.doc.data());
      });
      // console.log(this.pacientes);
    });
  }

  createPaciente() {
    if (this.nombre === null || this.nombre === '') {
      this.response = 'Debe escribir su nombre';
      this.error = true;
      return;
    }
    if (this.apellidos === null || this.apellidos === '') {
      this.response = 'Debe escribir sus apellidos';
      this.error = true;
      return;
    }
    if (this.fechanac === null || this.fechanac === '') {
      this.response = 'Debe introducir su fecha de nacimiento';
      this.error = true;
      return;
    }
    if (this.motivo === null || this.motivo === '') {
      this.response = 'Debe introducir su motivo de consulta';
      this.error = true;
      return;
    }

      const paciente = {
        nombre: this.nombre,
        apellidos: this.apellidos,
        fechanac: this.fechanac,
        id: this.nombre + Math.floor(100000 + Math.random() * 900000),
        motivo: this.motivo,
      };
      var idSala = this.idSala;
      this._pacientes.createPaciente(paciente)
        .catch((error) => {
            this.error = true;
            this.response = 'No se pudo crear el usuario'
        })
      this.form=false
      this.registrado=true;
      
      let url = "https://demo.gofacer.com/webapp/#/?conference=salaespera&name="+paciente.id+"&role=guest&callType=video";
      setTimeout(() => {
        window.location.href= url;
      }, 4500);
      
    }
  }

