import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
@Injectable()
export class InterceptorsService implements HttpInterceptor {
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const proxyReq = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin','*') });
      return next.handle(proxyReq);
    }
}

