import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ApirestService } from '../services/apirest.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccesoService } from '../services/acceso.service';

import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase';
import { FunctionCall } from '@angular/compiler';
import { AuthenticationService } from '../services/authentication-service.service';
@Component({
  selector: 'app-salaespera',
  templateUrl: './salaespera.component.html',
  styleUrls: ['./salaespera.component.css'],
})
export class SalaesperaComponent implements OnInit {
  userInfo: any;
  participantes: any;
  info: boolean = false;
  usuarios: any;

  constructor(
    public api: ApirestService,
    private http: HttpClient,
    public usersService: AccesoService,
    private functions: AngularFireFunctions, 
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
   
    this.getPacientes();
  }

  getPacientes = async () => {
    this.participantes = new Array();
    this.usuarios = new Array();
    const url =
      'https://us-central1-mg-enfermeria.cloudfunctions.net/app/pacientes';
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        data.objects.forEach((element) => {
          this.participantes.push(element);
        });
        this.participantes.forEach((element) => {
          this.usersService
            .getPaciente(element.participant_alias)
            .subscribe((dato) => {
              dato.forEach((usuar) => {
                var usu = usuar.data();
                if (usuar) {
                  var hora =  new Date(element.connect_time);
                  hora.setHours(hora.getHours()+2);
                  usu['horaConexion'] = hora;
                  usu['idVideo'] = element.id;
                  usu['idBBDD'] = usuar.id;
                  this.usersService.editPaciente(usu, usuar.id);
                  this.usuarios.push(usu);
                  // console.log(usu['idVideo']);
                }
              });
            });
        });
      });
  };

  getInfo(event) {
    this.info = true;
    this.userInfo = event;
    // console.log(this.userInfo);
  }

  transfer() {

    let id = this.userInfo.idVideo;

    const dataTrans = {
      participant_id: id,
      conference_alias: 'enfermera',
      role: 'guest',
    };
    const url =
    'https://us-central1-mg-enfermeria.cloudfunctions.net/app/Transferencia';
    fetch(url,{
      method:"POST",
      body:JSON.stringify(dataTrans)
    })


    var indice = this.usuarios.indexOf(this.userInfo); // obtenemos el indice
    this.usuarios.splice(indice, 1); // 1 es la cantidad de elemento a eliminar
    this.usersService.deletePaciente(this.userInfo.idBBDD);
    this.info = false;
    window.location.reload();
  }
  reload(){
    window.location.reload();
  }
  logout(){
    this.authService.logOut();
    window.location.reload();
  }
}
