import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SalaesperaComponent } from './salaespera/salaespera.component';
import { BoxsalaComponent } from './salaespera/boxsala/boxsala.component';
import { HttpClientModule } from '@angular/common/http';
import { FormComponent } from './form/form.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ApirestService } from './services/apirest.service';
import { InterceptorsService } from './services/interceptor.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    SalaesperaComponent,
    BoxsalaComponent,
    FormComponent,
    LoginComponent,
   
  

  ],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, 
    AngularFireDatabaseModule,
    FormsModule, 
    
  ],
  providers: [ApirestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService ,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
