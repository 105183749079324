// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: {
    baseURL: "https://admin.gofacer.com/api"
  },
    firebase: {
      apiKey: "AIzaSyDGioSGjB8tNd_uaA_eIfkYmOSN6VgZ3Io",
      authDomain: "mg-enfermeria.firebaseapp.com",
      projectId: "mg-enfermeria",
      storageBucket: "mg-enfermeria.appspot.com",
      messagingSenderId: "754119949841",
      appId: "1:754119949841:web:8bcfffcbb36d765ba1fbb0",
      measurementId: "G-1J7TB7FEDK"
    }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
